<template>
  <div class="products-list" v-if="products">
    <div class="product-item cur-p"
         v-for="(product, index) in products"
         :key="index"
         v-show="index < 4 || showAll"
         @click="setProductsSliderVisibility('show', index)"
    >
      <div class="product-item-content">
        <div class="product-thumbnail"
             :style="{ 'background-image' : (product.photo_url ? 'url(' + product.photo_url + ')' : 'none') }"
        ></div>
        <div class="product-info">
          <div class="product-info-content">
            <div class="product-name">{{ product.name }}</div>
<!--            <div class="product-description">{{ product.description }}</div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="products-slider"
         v-show="isProductsSliderVisible"
         ref="slider">
      <div class="products-slider-content">
        <div class="products-slider-close" @click="setProductsSliderVisibility('hide')"></div>
        <div class="slider-event-title">{{ companyName }}</div>
        <mu-carousel
            :active="activeSlideNumber"
            :cycle="false"
            :hide-indicators="true"
            :hide-controls="products.length < 2"
        >

          <span class="slide-arrow slide-arrow-right" slot="right"></span>
          <span class="slide-arrow slide-arrow-left" slot="left"></span>

          <mu-carousel-item
              class="products-slide"
              v-for="(product, index) in products"
              :key="index"
          >
            <div class="products-slide-content">
              <div class="product-photo"
                   :style="{'background-image': (product.photo_url) ? 'url(' + product.photo_url + ')' : 'none'}"
              ></div>
              <div class="product-info">
                <div class="product-name">{{ product.name }}</div>
                <div class="product-description">{{ product.description }}</div>
              </div>
            </div>
          </mu-carousel-item>
        </mu-carousel>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'productList',
  props: {
    companyName: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: function() {
        return [];
      }
    },
    showAll: { // Show all notes, not just 4
      type: Boolean,
      default: false
    },
  },
  data: function(){
    return {
      isProductsSliderVisible: false,
      activeSlideNumber: 0
    }
  },
  beforeDestroy() {
    /* If the slider is open, we have
       to explicitly hide and/or remove it because its element
       has been moved into another place in the DOM
    */
    this.setProductsSliderVisibility('hide');
    this.removeSliderFromBody();
  },
  methods: {
    /* Shows, hides or toggles the slider with full-size
     * products display
     */
    setProductsSliderVisibility(option, index = 0){

      this.activeSlideNumber = index;

      switch(option){
        case 'show':
          this.isProductsSliderVisible = true;
          this.moveSliderIntoBody();
          break;
        case 'toggle':
          this.isProductsSliderVisible = !this.isProductsSliderVisible;
          if(this.isProductsSliderVisible){
            this.moveSliderIntoBody();
          }
          break;
        case 'hide':
        default:
          // default is «hide». Feel free to change this arbitrary decision.
          this.isProductsSliderVisible = false;
      }
    },
    /* For independent z-indexation and fixed positioning,
     * lets do a small DOM-manipulation — move the slider
     * into body
     */
    moveSliderIntoBody(){
      const sliderElement = this.$refs.slider;
      if(!sliderElement || sliderElement.parentNode.tagName.toLowerCase() === 'body'){
        return;
      }
      sliderElement.parentNode.removeChild(sliderElement);
      document.getElementsByTagName('body')[0].appendChild(sliderElement);
    },
    /* Removes the slider from the DOM
     * when productList is itself destroyed
     */
    removeSliderFromBody(){
      if(typeof this.$refs.slider == 'undefined'){
        return;
      }
      const sliderElement = this.$refs.slider;
      if(!sliderElement || sliderElement.parentNode.tagName.toLowerCase() !== 'body'){
        return;
      }
      sliderElement.parentNode.removeChild(sliderElement);
    }
  }
}
</script>

<style lang="scss" scoped>
  .products-list {

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .product-item {
      margin-bottom: 26px;
      text-align: left;
      width: 25%;
      word-break: break-word;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: darken($ew-gray-lightest, 1%);
      }

      .product-item-content {
        max-width: 60px;
        position: relative;
        text-align: center;
      }

      .product-thumbnail {
        background-color: #f2f2f2;
        width: 100%;
        min-width: 60px;
        padding-bottom: 100%; /* Квадрат шириной в элемент-родитель */
        border-radius: 5px;
        background-size: contain;
        overflow: hidden;
        margin-bottom: 66px;
        background-position: center;
        background-repeat: no-repeat;
      }

      .product-info {
        position: absolute;
        top: 66px;
        left: 50%;
        max-width: 160%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        transform: translateX(-50%);
        font-size: 10px;
        line-height: 1.25;
        width: 100%;
      }

      .product-name {
        max-height: 8rem;
        text-overflow: ellipsis;
        margin-bottom: 0.75rem;
        overflow: hidden;

        @supports (-webkit-line-clamp: 4) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }

      .product-description {
        max-height: 1.25rem;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0.75rem;
        opacity: 0.5;
      }

      .product-action-delete {
        color: #ff6c6c;
      }

    }

    /*
    .products-list-full
    Display items in a vertical column, i.e. when the «Products» tab is open
    */
    &-full {
      flex-direction: column;
      margin-top: -29px; /* компенсация паддинга */

      .product-item {
        width: auto;
        margin: 0;
        padding: 29px 0;
        position: relative;

        &:hover {
          background-color: darken($ew-gray-lightest, 1%);
        }

        &::after {
          position: absolute;
          left: 10%;
          top: 100%;
          width: 80%;
          content: "";
          height: 1px;
          background-color: #707070;
          opacity: 0.1;
        }

        &:last-child {
          padding-bottom: 0;
        }

        &:last-child::after {
          display: none;
        }

        .product-item-content {
          width: 100%;
          max-width: 100%;
          display: flex;
          flex-flow: row nowrap;
          justify-items: flex-start;
          align-items: center;
        }

        .product-thumbnail {
          width: 60px;
          height: 60px;
          padding-bottom: 0;
          margin: 0 3rem 0 0;
        }

        .product-info {
          position: static;
          width: auto;
          max-width: none;
          transform: none;
          text-align: left;
        }

        .product-name {
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 400;
          max-height: 57px;
        }

        .product-description {
          display: none;
        }
      }

    }


    /* Поставил правило тут, потому что линтер и его no-descending-specificity, а мне надо до трех часов задеплоить
     * Будь проклят автор этого правила в линтере. Изверг чертов.
     */

    /* :rofl */

    .product-item:nth-child(4n) {
      margin-right: 0;
    }
  }

  /* * * * * * * * * * * *
   * Slider styles
   */
  .products-slider {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $z-index-products-slider;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 5px 5px 15px rgba(55, 84, 170, 0.1);
    width: calc(100vw - 141px - 141px);
    height: calc(100vh - 145px);
    box-sizing: border-box;
    padding: 35px 40px 77px;

    .products-slider-content {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: stretch;

      .mu-carousel {
        flex: 1;
      }
    }

    .slider-event-title {
      font-size: 2.4rem;
      line-height: 2.9rem;
      padding-bottom: 3.6rem;
      border-style: solid;
      border-width: 0 0 1px;
      border-color: rgba(112, 112, 112, 0.1);
      margin-bottom: 5rem;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .products-slider-close {
      position: absolute;
      z-index: 5;
      top: 0;
      right: 0;
      width: 21px;
      height: 21px;
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        height: 2px;
        width: 80%;
        position: absolute;
        transform-origin: center;
        left: 0;
        top: 50%;
        margin-top: -1px;
        transform: rotate(45deg);
        background-color: #000;
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    .products-slide {
      height: 100%;
    }

    .products-slide-content {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      height: 100%;
      max-height: 100%;
      min-height: 100%;

      .product-photo {
        flex: 1;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .product-info {
        width: 100%;
        padding-top: 2.8rem;
        max-width: 830px;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
      }

      .product-name {
        font-size: 2.4rem;
        font-weight: 300;
        line-height: 2.9rem;
      }

      .product-description {
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 2rem;
      }

    }

    .slide-arrow {

      position: absolute;
      top: 50%;
      left: 50%;
      width: 36px;
      height: 72px;
      transform: translateY(-50%);

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        content: "";
        width: 150%;
        height: 2px;
        background-color: #000;
      }

      &:hover {
        opacity: 0.75;
      }

      &-left {
        &::before,
        &::after {
          transform-origin: left center;
          transform: rotate(50deg);
        }

        &::after {
          transform: rotate(-50deg);
        }
      }

      &-right {
        &::before,
        &::after {
          left: auto;
          right: 10px;
          transform-origin: right center;
          transform: rotate(50deg);
        }

        &::after {
          transform: rotate(-50deg);
        }
      }

    }

  }
</style>
<style lang="css">
  /* TODO: убрать эти CSS-костыли, которые не успеваю разрулить так, как это считается правильным во Vue. */
  .products-slider .mu-carousel-button {
    overflow: visible !important;
  }

  .products-slider .mu-ripple-wrapper {
    display: none !important;
  }
</style>
