<template>
  <div class="head-nav">
    <biletumSharer :url="shareLink" :position="{t:0,l:40}"
                   ref="biletumSharer"></biletumSharer>
    <div class="btns-group" v-if="leftNav">
      <a href="javascript:void(0);" @click="close()">
        <icon-close-circle-outline/>
      </a>
      <button
        class="button button-unpin"
        :title="$t('button.unpin')"
        :disabled="isUnpinButtonDisabled"
        @click="onUnpinBroadcastClick"
      >
        <icon-unpin></icon-unpin>
      </button>
      <a href="javascript:void(0);"  @click="fullscreenMode">
        <iconZoomIn/>
      </a>
      <button
        v-show="isContactTeamMember"
        class="button button-start-broadcast"
        :title="$t('meetingRooms[\'Start broadcast\']')"
        @click="onStartBroadcastClick"
      >
        <icon-broadcast />
        <promo-broadcast-menu
          v-if="isPromoBroadcastMenuVisible"
          :type="BroadcastType.PROMO_CONTACT"
        ></promo-broadcast-menu>
      </button>
    </div>

    <div class="btns-group btns-group-center">
<!--      <button TODO : remove but not now-->
<!--        class="control control-button button-video-chat-room"-->
<!--        style="display: none;"-->
<!--        :class="{ flicker: isVideoChatActive(videoChatState) && canJoinVideoChatRoom }"-->
<!--        :disabled="!canJoinVideoChatRoom"-->
<!--        @click="onVideoChatRoomButtonClick"-->
<!--      >-->
<!--       {{ $t('[\'video-chat-room\'][\'Video chat room\']') }}-->
<!--      </button>-->
    </div>

    <div class="btns-group">

      <a
        v-if="videoStreamUrl"
        class="button-video-stream"
        :href="videoStreamUrl"
        target="_blank"
      >
        <icon-videocam color="#00b6f8" />
      </a>

      <!--      <a href="javascript:void(0);" @click="goToOwnerMessages()">-->
      <!--        <icon-message/>-->
      <!--      </a>-->
      <a href="javascript:void(0);"
         @click.stop="share()"
         @mouseover="setShareColor('#57C7FF')"
         @mouseleave="setShareColor('#707070')">
        <icon-share :color="shareColor"/>
      </a>
      <a href="javascript:void(0);"
         @click="toggleFavorite()">
        <i class="material-icons">
          <icon-star :color="color" :active="isActiveIcon"/>
        </i>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import iconCloseCircleOutline from '@/_modules/icons/components/icon-close-circle-outline.vue';
import iconZoomIn from '@/_modules/icons/components/icon-zoom-in.vue';
import iconVideocam from '@/_modules/icons/components/icon-videocam.vue';
// import iconMessage from '../../../svg/icons/Icon-message';
import iconShare from '@/_modules/icons/components/icon-share.vue';
import IconStar from '@/_modules/icons/components/icon-star.vue';
import biletumSharer from "../../popups/biletumSharer"
import UrlHelper from '@/_helpers/url.helper';
import IconBroadcast from "@/_modules/icons/components/icon-broadcast.vue";
import PromoBroadcastMenu from "@/_modules/promo/components/promo-broadcast-menu/promo-broadcast-menu";
import IconUnpin from '@/_modules/icons/components/icon-unpin.vue';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import eventDiscoveryService from '@/_services/event-discovery.service';
import EventHelper from "@/_helpers/event.helper";
import { TimeStatus } from "@/_types/time-status.enum";

export default {
  name: 'company-head-nav',
  components: {
    PromoBroadcastMenu,
    IconStar,
    iconCloseCircleOutline,
    iconZoomIn,
    iconVideocam,
    // iconMessage,
    iconShare,
    IconBroadcast,
    biletumSharer,
    IconUnpin,
  },
  computed: {
    ...mapGetters({
      promoPageByExternalId: 'promoStore/promoPageByExternalId',
      contactInfo: 'promoPageStore/contact',
      userInfo: '_userStore/user',
      event: '_eventStore/event',
      getCompanyVideoChatStateByExternalId: 'meetingRoomsStore/getCompanyVideoChatStateByExternalId',
    }),
    videoStreamUrl() {
      return this.video_stream_url ? UrlHelper.addMissingUrlSchema(this.video_stream_url) : '';
    },
    isContactTeamMember() {
      const contactId = this.contactId;
      const attachedContacts = this.attachedContacts;
      if (!contactId || !attachedContacts || !attachedContacts.length) {
        return false;
      }
      return !!(attachedContacts.find(c => c.contact.id === contactId));
    },
    eventId() {
      return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
    },
    externalId() {
      return this.$route.params.external_id ? this.$route.params.external_id : null;
    },
    contactId() {
      return (this.contactInfo && this.contactInfo.id) || null;
    },
    company() {
      return this.promoPageByExternalId(this.externalId);
    },
    attachedContacts() {
      const company = this.company;
      return (company && company.attached_contacts) || [];
    },
    videoChatState() {
      return this.getCompanyVideoChatStateByExternalId(this.externalId);
    },
    canJoinVideoChatRoom() {
      const contactId = this.contactId;
      const videoChatState = this.videoChatState;
      const isContactTeamMember = this.isContactTeamMember;

      if (!contactId) {
        return false;
      }

      const isVideoChatActive = this.isVideoChatActive(videoChatState);
      if (eventDiscoveryService.isConnected() && isContactTeamMember && !isVideoChatActive) {
        return true;
      }

      return isVideoChatActive;

      // return !!(
      //   videoChatState
      //   && (
      //     videoChatState.haveFreeSpots
      //     || videoChatState.moderatorContactId === contactId
      //     || !!videoChatState.participants.find(p => p && p.contactId === contactId)
      //   )
      // );
    },
  },
  mounted() {
    this.subscribeToPageEvents();
  },
  beforeDestroy() {
    this.unsubscribeFromPageEvents();
  },
  watch: {

    company: {
      immediate: true,
      deep: true,
      handler() {
        this.isUnpinButtonDisabled = !this.company || !this.company.activeMediaItem
          || this.company.activeMediaItem.type === 'image';
        this.activeMediaItem = (this.company && this.company.activeMediaItem) || null;
      }
    },

    leftNav: {
      handler() {
        this.isShow = this.leftNav;
      }
    },
    isFavoriteCompany: {
      immediate: true,
      deep: true,
      default: false,
      handler() {
        this.favorite = this.isFavoriteCompany;
      }
    },
    favorite: {
      immediate: true,
      handler() {
        this.setFavColor();
      }
    },
  },
  props: {
    leftNav: {
      type: Boolean,
      default: false,
      handler() {
        this.isShow = this.leftNav;
      }
    },
    isFavoriteCompany: {
      type: Boolean,
      required: true,
      default: false,
      handler() {
        this.favorite = this.isFavoriteCompany;
      }
    },
    video_stream_url: {
      type: String,
      default: ''
    },
    external_id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      BroadcastType: BroadcastType,
      isShow: true,
      favorite: false,
      color: '',
      isActiveIcon: false,
      shareColor: '#707070',
      target: '',
      shareLink: '',
      isPromoBroadcastMenuVisible: false,
      isUnpinButtonDisabled: true,
      activeMediaItem: null,
    }
  },
  methods: {

    isVideoChatActive(videoChatState) {
      const currentTimestamp = Math.floor((new Date()).getTime() * 0.001);
      return eventDiscoveryService.isConnected()
        && !!videoChatState
        && videoChatState.expire > currentTimestamp
        && videoChatState.isPublished
        && !!videoChatState.participants[0];
    },

    onVideoChatRoomButtonClick() {
      if (!this.canJoinVideoChatRoom) {
        return;
      }

      const contactId = this.contactId;
      const videoChatState = this.videoChatState;
      const isContactTeamMember = this.isContactTeamMember;

      const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);

      if (eventTimeStatus === TimeStatus.PAST || eventTimeStatus === TimeStatus.FUTURE) {
        this.$store.dispatch('_eventStore/setIsChatRoomTimeCheckDialogVisible', true);
        return;
      }

      if (!videoChatState && isContactTeamMember) {
        const meetingRoomConfig = {
          type: 'company',
          eventId: this.eventId,
          contactId: contactId,
          moderatorContactId: contactId,
          externalId: this.externalId,
        };
        this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
      } else if (videoChatState) {
        const meetingRoomConfig = {
          type: 'company',
          eventId: this.eventId,
          contactId: contactId,
          moderatorContactId: videoChatState.moderatorContactId,
          externalId: this.externalId,
        };
        this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
      }
    },

    onUnpinBroadcastClick() {
      if (!this.activeMediaItem) {
        return;
      }
      const node = document.getElementById('company-media-item-' + this.activeMediaItem.id);

      this.$store.dispatch('promoStore/unpinMediaItem', {
        externalId: this.externalId,
        id: this.activeMediaItem.id,
      });

      if (node && (this.activeMediaItem.type === 'video' || this.activeMediaItem.type === 'embed')) {
        this.$nextTick(() => {
          const container = document.getElementById('media-item-container-' + this.activeMediaItem.id);
          if (container) {
            container.append(node);
          }
        });
      }
    },
    close() {
      this.$emit('closeItem')
    },
    async toggleFavorite() {
      this.favorite = !this.favorite;
      await this.$emit("promoFavoriteCompanyEvent", this.favorite)
      this.setFavColor();
    },
    setFavColor() {
      if (this.favorite) {
        this.color = '#00b6f8'
        this.isActiveIcon = true;
      } else {
        this.color = '#000'
        this.isActiveIcon = false;
      }
    },
    goToOwnerMessages() {
      this.$emit('promoGoToOwnerMessages')
    },
    setShareColor(color) {
      this.shareColor = color;
    },
    share() {
      this.shareLink = window.location.protocol + '//' + window.location.host + this.$route.path;
      this.$refs.biletumSharer.toggleSharer();
    },
    onStartBroadcastClick(event) {
      event.stopPropagation();
      this.isPromoBroadcastMenuVisible = !this.isPromoBroadcastMenuVisible;

      const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
      if (eventTimeStatus === TimeStatus.PAST || eventTimeStatus === TimeStatus.FUTURE) {
        this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
        return;
      }
      const meetingRoomConfig = this.meetingRoomConfig;
      if (!meetingRoomConfig) {
        return;
      }
      this.$store.dispatch('_eventStore/setZoomSettingsDialogConfig', meetingRoomConfig);
    },
    fullscreenMode() {
      this.$emit('enable-fullscreen-mode', true);
    },
    subscribeToPageEvents() {
      document.addEventListener('click', this.onDocumentClick);
    },
    unsubscribeFromPageEvents() {
      document.removeEventListener('click', this.onDocumentClick);
    },
    onDocumentClick() {
      this.isPromoBroadcastMenuVisible = false;
    }
  }

}

</script>

<style scoped lang="scss">
@keyframes flickerAnimation { /* flame pulses */
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
}

.head-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .btns-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;

    &-center {
      flex: 1 1 0;
      justify-content: center;
    }

    &:last-child {
      margin-left: auto;
    }

    .button-video-chat-room {
      height: 22px;
      padding: 2px 10px 0;
      background: #eb5b58;
      border-radius: 9px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #fff;

      &.flicker {
        animation: flickerAnimation 3s infinite;
      }

      &[disabled] {
        background: #d8d8d8;
      }
    }

    a {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      a {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        i {
          font-size: 14px;
          color: $ew-black;
        }

      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      width: 24px;
      height: 24px;
      border: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:disabled {
        cursor: default;
      }
    }

    .button-unpin {
      margin-right: 10px;

      &:disabled {
        /deep/ .icon {
          stroke: #888;

          .box {
            fill: #888;
          }
        }
      }
    }

    .button-start-broadcast {
      position: relative;

      /deep/ .promo-broadcast-menu {
        position: absolute;
        z-index: 10;
        top: 100%;
        left: 100%;
      }
    }
  }
}
</style>
